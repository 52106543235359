// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background: #fff;
    padding: 12px;
    display: flex;
    align-items: center;
}
.header.tracker {
    justify-content: flex-end;
}

.header.tracker h2 {
    display: none;
}

.header h2 {
    font-family: 'Noto Serif Telugu';
    font-weight: 700;
    font-size: 20px;
    line-height: 117.68%;
    display: flex;
    align-items: center;
    color: #383737;
    margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gCAAgC;IAChC,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".header {\n    background: #fff;\n    padding: 12px;\n    display: flex;\n    align-items: center;\n}\n.header.tracker {\n    justify-content: flex-end;\n}\n\n.header.tracker h2 {\n    display: none;\n}\n\n.header h2 {\n    font-family: 'Noto Serif Telugu';\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 117.68%;\n    display: flex;\n    align-items: center;\n    color: #383737;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
