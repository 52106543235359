import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import Header from '../../components/Header'
import './styles.css';
import truck from '../../images/truck.svg';
import button from '../../images/button.svg';
import search_small from '../../images/search_small.svg';

const Home = () => {
  const [orderId, setOrderId] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event, className) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const checkOrder = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/track/${orderId}`);
      if(checkOrder?.data?.success === true){
        setLoading(false);
        setError("")
        navigate(`/track/${orderId}`, { state: { orderId: orderId } });
      }
      
    } catch (error) {
      setLoading(false);
      setError(error.response.data.error);
      document.getElementById(className).classList.add("shake");
      return;
    }
  };

  const handleChange = (event, className) => {
    setOrderId(event.target.value);
    setError("");
    document.getElementById(className).classList.remove("shake");
  };


  return (
    <>
      {
        window.innerWidth > 600 ? (
          <div style={{ position: "relative" }}>
            <Header tracker={false} />
            
            <div className="modal__mask">
              <div className="modal__wrapper" id="modal" >
                <div className="modal__header">
                  <img src={truck} alt="truck-logo" />
                  <h1>Velozity</h1>
                </div>
                <div className="modal__body">
                  <h1>Track  Order</h1>
                  <div className="input__wrap">
                    <input
                      type="text"
                      value={orderId}
                      onChange={(e) => handleChange(e, 'modal')}
                      placeholder="Enter your given Tracking ID"
                    />
                    {error && <div className="error">{error}</div>}
                  </div>
                  <button className="track-btn" onClick={(e) => handleSubmit(e, 'modal')}>{loading ? "... Getting Data" : "Track Order"}</button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mb__wrap">
            <div className="modal__wrap">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={button} alt="" />
              </div>
              <div className="mb__form" id="mb__form">
                <h1>Track Your Order</h1>
                <p>Enter the order Id that you got.</p>
                <div className="input__wrapper">
                  <input
                    type="text"
                    placeholder="Enter your order Id"
                    value={orderId}
                    onChange={(e) => handleChange(e, 'mb__form')}
                  />
                  {error && <div className="error">{error}</div>}
                  <div className="circle">
                    <img src={search_small} alt="" />
                  </div>
                </div>
                <button onClick={(e) => handleSubmit(e, 'mb__form')}>TRACK MY ORDER</button>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Home;
