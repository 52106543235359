import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyAl2iWGGA2I52Tc07POfYLD0nBKBrbF7NI",
  authDomain: "velozity-a24bf.firebaseapp.com",
  projectId: "velozity-a24bf",
  storageBucket: "velozity-a24bf.appspot.com",
  messagingSenderId: "633606124109",
  appId: "1:633606124109:web:3a4dba222863ee3e0ee204",
  measurementId: "G-TLFZEYE10R"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);

export default database;