import React, { useState, useEffect } from "react";
import axios from 'axios'
import Maps from "./maps";
import { useLoadScript } from "@react-google-maps/api";
import { useParams } from 'react-router-dom';

import Header from '../../components/Header'
import './styles.css';
import Sidebar from "./Sidebar";


const TrackOrder = () => {
  const params = useParams();
  const [bottomOpen, setBottomOpen] = useState(true);
  const [inactive, setInactive] = useState(false);
  const [data, setData] = useState(null);


  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY,
  });

  const isMobile = window.innerWidth <= 600;

  const fetchData = async () => {
    try {
      const getOrderStatus = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/track/${params?.id}`);
      if(getOrderStatus.data?.success === true){
        setData(getOrderStatus?.data?.data)
      }
      
    } catch (error) {
      // alert(error.response.data.error)
    }
  };


  useEffect(() => {
    fetchData();
  }, [params?.id])


  return (
    <div style={{ position: "relative" }}>
      {
        !isMobile && <Header tracker={true} />
      }
      <div style={{ background: "#F3F6FA" }}>
        {isLoaded && data ? (
          <div className={`maps__wrapper ${inactive ? "map-inactive" : ""}`}>
            <Maps customerAddress={data?.customer?.address} partner={data?.partner}/>
          </div>
        ) : null}
      </div>
      <Sidebar orderId={params?.id} bottomOpen={bottomOpen} setBottomOpen={setBottomOpen} inactive={inactive} setInactive={setInactive}/>
    </div>
  );
};

export default TrackOrder;
