import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, DirectionsRenderer } from "@react-google-maps/api";
import { ref, onValue, off } from "firebase/database";
import database from "../../firebase/database";

import Car from "../../images/car.png";
import Customer from "../../images/DeliveredWhite.svg";
import Partner from "../../images/PickedUpWhite.svg";

function Map({ bottomOpen, customerAddress, partner }) {
  const containerHeight =
    window.innerWidth <= 600 && !bottomOpen ? "75vh" : "calc(100vh - 115px)";
  const [data, setData] = useState(null);
  const [directions, setDirections] = useState(null);

  useEffect(() => {
    const dataRef = ref(database, `Driver_livelocation/${partner?._id}`);

    const unsubscribe = onValue(dataRef, (snapshot) => {
      const dbData = snapshot.val();
      setData(dbData);
    });

    return () => off(dataRef, "value", unsubscribe);
  }, [partner, database]);

  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();
    if (partner?.address?.lat && customerAddress?.lat) {
      directionsService.route(
        {
          origin: { lat: partner?.address?.lat, lng: partner?.address?.lng },
          destination: { lat: customerAddress?.lat, lng: customerAddress?.lng },
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [partner?.address, customerAddress]);

  return (
    <GoogleMap
      center={data}
      mapContainerStyle={{ width: "100%", height: containerHeight }}
      zoom={15}
      options={{
        gestureHandling: "greedy",
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      {directions && <DirectionsRenderer directions={directions} />}
      <MarkerF
        key={data}
        position={data}
        title="Driver Live Locations"
        icon={Car}
      />
    </GoogleMap>
  );
}

export default React.memo(Map);
