// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html, form, fieldset, ol, ul, li, h1, h2, h3, h4, h5, h6, p {
    margin:0;
    padding:0;
}

body {
    background: #F3F6FA;
}
 `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["body, html, form, fieldset, ol, ul, li, h1, h2, h3, h4, h5, h6, p {\n    margin:0;\n    padding:0;\n}\n\nbody {\n    background: #F3F6FA;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
